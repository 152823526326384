<template>
    <div class="row m-2 p-2">
      <head>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />
      </head>
      <!-- Modal FOR ADD HSN -->
      <!-- <div v-if="toggleModal">
        <H1>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium expedita rerum dignissimos eos molestiae autem est consequuntur dolor, reiciendis ipsam aliquam eaque possimus, delectus aspernatur, eligendi qui molestias dolorum sequi!
        </H1>
    
    </div> -->
      
  
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-info shadow-success border-radius-lg pt-4 pb-3 row"
              >
                <h6 class="col-8 text-white text-capitalize ps-3">Vendor</h6>
                <div class="col-4 d-flex justify-content-end align-item-center">
                  <span class="badge badge-sm bg-white text-info d-flex  align-items-center cursor-pointer "
                      
                  >
                       
                        
                        <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-2  ">person_add</i>
    
                            Add Vendor
                        
                      
                      </span
                  >
                  <!-- <a-modal v-modal:open="open" title="Basic Modal" @ok="handleok">
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                  </a-modal> -->
                </div>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th
                        class="text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        S.No.
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9 ps-2"
                      >
                        Name
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Vendor Id
                      </th>
                      
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Email
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Number
                      </th>
                      <th class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="vendor in all_vendors" :key="vendor.sno">
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{ vendor.sno }}</span
                        >
                      </td>
                      
                      <td class="align-middle text-center">
                        <h6  class="mb-0 text-sm inline"> {{ vendor.name }}</h6  >
                        
                        
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold border border-secondary py-1 px-2"
                          >{{ vendor.vendor_id }}</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{ vendor.email }}</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          > {{ vendor.number }}</span
                        >
                      </td> 
                      
                      <td class="align-middle text-center">
                        <a
                          href="javascript:;"
                          class="text-secondary font-weight-bold text-xs"
                          data-toggle="tooltip"
                          data-original-title="Edit user"
                        >
                          <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-1">edit</i>
                          <i class="material-icons-round opacity-10 fs-6  text-white bg-danger p-1 rounded-circle mx-1">delete</i>
                      
                        </a>
                      </td>
                    </tr>
                    
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
  </template>
  

<script>
export default {
    name:'Vendor',
    data(){
        return {
            all_vendors:[
                {
                    sno:'1',
                    name:'Vendor1',
                    vendor_id:'V00001',
                    email:'v1@gmail.com',
                    number:'111111'
                },
                {
                    sno:'2',
                    name:'Vendor2',
                    vendor_id:'V00002',
                    email:'v2@gmail.com',
                    number:'22222'
                },
                {
                    sno:'3',
                    name:'Vendor3',
                    vendor_id:'V00003',
                    email:'v3@gmail.com',
                    number:'33333'
                },
                {
                    sno:'4',
                    name:'Vendor4',
                    vendor_id:'V00004',
                    email:'v1@gmail.com',
                    number:'44444'
                },
                {
                    sno:'5',
                    name:'Vendor5',
                    vendor_id:'V00005',
                    email:'v5@gmail.com',
                    number:'55555'
                }
            ]
        }
    },
    methods:{

    }
}
</script>

<style>

</style>