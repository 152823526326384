<template>
    <div class="row m-2 p-2">
      <head>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />
      </head>
      <!-- Modal FOR ADD HSN -->
      <!-- <div v-if="toggleModal">
        <H1>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium expedita rerum dignissimos eos molestiae autem est consequuntur dolor, reiciendis ipsam aliquam eaque possimus, delectus aspernatur, eligendi qui molestias dolorum sequi!
        </H1>
    
    </div> -->
      
  
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-info shadow-success border-radius-lg pt-4 pb-3 row"
              >
                <h6 class="col-8 text-white text-capitalize ps-3">Medicine</h6>
                <div class="col-4 d-flex justify-content-end align-item-center">
                  <span class="badge badge-sm bg-white text-info d-flex  align-items-center cursor-pointer "
                      
                  >
                        
                          
                        
                        <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-2  ">add</i>
                        Add Medicine
                        
                      
                      </span
                  >
                  <!-- <a-modal v-modal:open="open" title="Basic Modal" @ok="handleok">
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                  </a-modal> -->
                </div>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th
                        class="text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        S.No.
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9 ps-2"
                      >
                        Medicine
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Composition
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9 ps-2"
                      >
                        Brand Name
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9 ps-2"
                      >
                        Packing
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Type
                      </th>
                      
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Group
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Price
                      </th>
                      <th class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="medicine in all_medicines" :key="medicine.sno">
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{ medicine.sno }}</span
                        >
                      </td>
                      
                      <td class="align-middle text-center">
                        <h5 class="mb-0 text-sm inline"> {{ medicine.name }}</h5>
                        <!-- <h6 class="mb-0 text-sm inline"> {{ medicine.brand }}</h6> -->
                        <!-- <p class="text-xs text-secondary mb-0">
                            {{ medicine.category }}
                        </p>
                       
                        <p class="text-xs text-secondary mb-0">
                            {{ medicine.packing }}
                        </p>
                        <p class="text-xs text-secondary mb-0">
                            {{ medicine.Packing }}
                        </p> -->
                        
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{ medicine.Composition }}</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{ medicine.brand }}</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{ medicine.packing }}</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{ medicine.category }}</span
                        >
                      </td> 
                     
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          > {{ medicine.Group }}</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{ medicine.price }}</span
                        >
                      </td> 
                      
                      <td class="align-middle text-center">
                        <a
                          href="javascript:;"
                          class="text-secondary font-weight-bold text-xs"
                          data-toggle="tooltip"
                          data-original-title="Edit user"
                        >
                          <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-1">edit</i>
                          <i class="material-icons-round opacity-10 fs-6  text-white bg-danger p-1 rounded-circle mx-1">delete</i>
                      
                        </a>
                      </td>
                    </tr>
                    
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
  </template>
  

<script>
export default {
    name: 'Medicine',
    data() {
        return {
            all_medicines:[
                {
                    sno:'1',
                    name:'Paracetamol',
                    medicine_id:'M00001',
                    category:'Tablet',
                    brand:'Calpol',
                    Composition:'Paracetamol-500',
                    Group:'Analgesc | Antipyretic',
                    packing:'10\' s',
                    price:'25'
                },
                {
                    sno:'1',
                    name:'Paracetamol',
                    medicine_id:'M00001',
                    category:'Tablet',
                    brand:'Calpol',
                    Composition:'Paracetamol-500',
                    Group:'Analgesc | Antipyretic',
                    packing:'10\' s',
                    price:'25'
                },
                {
                    sno:'1',
                    name:'Paracetamol',
                    medicine_id:'M00001',
                    category:'Tablet',
                    brand:'Calpol',
                    Composition:'Paracetamol-500',
                    Group:'Analgesc | Antipyretic',
                    packing:'10\' s',
                    price:'25'
                },
                {
                    sno:'1',
                    name:'Paracetamol',
                    medicine_id:'M00001',
                    category:'Tablet',
                    brand:'Calpol',
                    Composition:'Paracetamol-500',
                    Group:'Analgesc | Antipyretic',
                    packing:'10\' s',
                    price:'25'
                },
                {
                    sno:'1',
                    name:'Paracetamol',
                    medicine_id:'M00001',
                    category:'Tablet',
                    brand:'Calpol',
                    Composition:'Paracetamol-500',
                    Group:'Analgesc | Antipyretic',
                    packing:'10\' s',
                    price:'25'
                },
                {
                    sno:'1',
                    name:'Paracetamol',
                    medicine_id:'M00001',
                    category:'Tablet',
                    brand:'Calpol',
                    Composition:'Paracetamol-500',
                    Group:'Analgesc | Antipyretic',
                    packing:'10\' s',
                    price:'25'
                },
                {
                    sno:'1',
                    name:'Paracetamol',
                    medicine_id:'M00001',
                    category:'Tablet',
                    brand:'Calpol',
                    Composition:'Paracetamol-500',
                    Group:'Analgesc | Antipyretic',
                    packing:'10\' s',
                    price:'25'
                },
            ]
        }
    },
    methods:{

    }
}
</script>

<style>

</style>