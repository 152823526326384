<template>
  <div>
    hi this is profile 2
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio non cupiditate, placeat officiis nesciunt quam velit debitis tempore fugit consectetur laudantium voluptatem rerum animi autem ex totam accusantium cum esse. Fugiat consectetur dolorem magnam asperiores quisquam quia aperiam voluptates nam reiciendis eligendi iure quam iste praesentium quos, suscipit nulla veniam, soluta commodi beatae cum! Impedit laboriosam explicabo repellat eveniet vero quia velit amet accusamus error reprehenderit repellendus eos ipsa cum tenetur minima sint adipisci officia dolores, itaque, dolorem quas! Beatae, saepe! Cumque, architecto perspiciatis dignissimos, rerum rem vitae debitis sapiente dolore iure quibusdam nesciunt sit, minus temporibus maxime voluptatem fugit.</p>
  </div>
</template>

<script>
export default {
    name: 'Profile2'
}
</script>

<style>

</style>