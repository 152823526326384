<template>
    <div class="row m-2 p-2">
      <head>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />
      </head>
      <!-- Modal FOR ADD HSN -->
      <!-- <div v-if="toggleModal">
        <H1>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium expedita rerum dignissimos eos molestiae autem est consequuntur dolor, reiciendis ipsam aliquam eaque possimus, delectus aspernatur, eligendi qui molestias dolorum sequi!
        </H1>
    
    </div> -->
      
  
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-info shadow-success border-radius-lg pt-4 pb-3 row"
              >
                <h6 class="col-8 text-white text-capitalize ps-3">Medicine Stock</h6>
                <div class="col-4 d-flex justify-content-end align-item-center">
                  <span class="badge badge-sm bg-white text-info d-flex  align-items-center cursor-pointer "
                      
                  >
                        
                          
                        
                        <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-2  ">add</i>
                        Add Stock
                        
                      
                      </span
                  >
                  <!-- <a-modal v-modal:open="open" title="Basic Modal" @ok="handleok">
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                  </a-modal> -->
                </div>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <!-- <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th
                        class="text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        S.No.
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9 ps-2"
                      >
                        Medicine Name
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Quantity
                      </th>
                      
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Expiry Date
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Batch No
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        MRP(in Rs.)
                      </th>
                      
                      <th class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="stock in all_stocks" :key="stock.sno">
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{ stock.sno }}</span
                        >
                      </td>
                      
                      <td class="align-middle text-center">
                        <h6 class="mb-0 text-sm inline"> {{ stock.medicine_name }}</h6>
                        
                        
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold border border-secondary py-1 px-2"
                          >{{ stock.quantity }}</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{ stock.expiry_date }}</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          > {{ stock.batch_no }}</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          > {{ stock.mrp }}</span
                        >
                      </td> 
                      
                      
                      <td class="align-middle text-center">
                        <a
                          href="javascript:;"
                          class="text-secondary font-weight-bold text-xs"
                          data-toggle="tooltip"
                          data-original-title="Edit user"
                        >
                          <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-1">edit</i>
                          <i class="material-icons-round opacity-10 fs-6  text-white bg-danger p-1 rounded-circle mx-1">delete</i>
                      
                        </a>
                      </td>
                    </tr>
                    
                    
                  </tbody>
                </table> -->
              </div>
            </div>
          </div>
        </div>
      </div>
  </template>
  

<script>
export default {
    name: 'Stocks',
    data() {
        return {
            // all_stocks:[
            //     {
            //         sno:'1',
            //         medicine_name:'Paracetamol',
            //         quantity:'200',
            //         expiry_date:'21/04/2025',
            //         batch_no:'B-0193',
            //         mrp:'25',
            //         margin :''  
            //     },
                
            //     {
            //         sno:'1',
            //         medicine_name:'Paracetamol',
            //         quantity:'200',
            //         expiry_date:'21/04/2025',
            //         batch_no:'B-0193',
            //         mrp:'25',
            //         margin :''  
            //     },
                
            //     {
            //         sno:'1',
            //         medicine_name:'Paracetamol',
            //         quantity:'200',
            //         expiry_date:'21/04/2025',
            //         batch_no:'B-0193',
            //         mrp:'25',
            //         margin :''  
            //     },
                
            //     {
            //         sno:'1',
            //         medicine_name:'Paracetamol',
            //         quantity:'200',
            //         expiry_date:'21/04/2025',
            //         batch_no:'B-0193',
            //         mrp:'25',
            //         margin :''  
            //     },
                
            //     {
            //         sno:'1',
            //         medicine_name:'Paracetamol',
            //         quantity:'200',
            //         expiry_date:'21/04/2025',
            //         batch_no:'B-0193',
            //         mrp:'25',
            //         margin :''  
            //     },
                
              
            // ]
        }
    },
    methods:{
       
    }
}
</script>

<style>

</style>    