<template>
    <div class="row m-2 p-2">
      <head>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />
      </head>
      <!-- Modal FOR ADD HSN -->
      <!-- <div v-if="toggleModal">
        <H1>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium expedita rerum dignissimos eos molestiae autem est consequuntur dolor, reiciendis ipsam aliquam eaque possimus, delectus aspernatur, eligendi qui molestias dolorum sequi!
        </H1>
    
    </div> -->
      
  
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-info shadow-success border-radius-lg pt-4 pb-3 row"
              >
                <h6 class="col-8 text-white text-capitalize ps-3">Stores</h6>
                <div class="col-4 d-flex justify-content-end align-item-center">
                  <span class="badge badge-sm bg-white text-info d-flex  align-items-center cursor-pointer "
                      
                  >
                        
                          
                        
                        <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-2  ">add</i>
                        Add Store
                        
                      
                      </span
                  >
                  <!-- <a-modal v-modal:open="open" title="Basic Modal" @ok="handleok">
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                  </a-modal> -->
                </div>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <!-- <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th
                        class="text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        S.No.
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9 ps-2"
                      >
                        Store Type
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Store Id
                      </th>
                      
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        State
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        GST No.
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Contact No.
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Address
                      </th>
                      
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Pincode
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Status
                      </th>
                      <th class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="store in all_stores" :key="store.sno">
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{ store.sno }}</span
                        >
                      </td>
                      
                      <td class="align-middle text-center">
                        <h6 class="mb-0 text-sm inline"> {{ store.store_type }}</h6>
                        
                        
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold border border-secondary py-1 px-2"
                          >{{ store.store_id }}</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{ store.state }}</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          > {{ store.gst_no }}</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          > {{ store.contact_no }}</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          > {{ store.address }}</span
                        >    
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold  cursor-pointer" style="max-width: 100px;"
                            
                            
                          >{{ store.pincode }}</span
                        >
                      </td> 
                      
                      <td class="align-middle text-center">
                        <span :class="status_class(store.status)" style="max-width: 100px;"
                            
                            
                          >{{ store.status }}</span
                        >
                      </td> 
                      
                      <td class="align-middle text-center">
                        <a
                          href="javascript:;"
                          class="text-secondary font-weight-bold text-xs"
                          data-toggle="tooltip"
                          data-original-title="Edit user"
                        >
                          <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-1">edit</i>
                          <i class="material-icons-round opacity-10 fs-6  text-white bg-danger p-1 rounded-circle mx-1">delete</i>
                      
                        </a>
                      </td>
                    </tr>
                    
                    
                  </tbody>
                </table> -->
              </div>
            </div>
          </div>
        </div>
      </div>
  </template>
  

<script>
export default {
    name: 'Stores',
    data() {
        return {
            // all_stores:[
            //     {
            //         sno:'1',
            //         store_type:'Aarogya',
            //         store_id:'A00001',
            //         state:'New Delhi',
            //         gst_no:'GSTIN124532',
            //         contact_no:'9852147545',
            //         address :'Address1',
            //         pincode :'101010',
            //         status:'Active'
            //     },
            //     {
            //         sno:'2',
            //         store_type:'Retail Franchise',
            //         store_id:'A00002',
            //         state:'Madhya Pradesh',
            //         gst_no:'GSTIN956482',
            //         contact_no:'9852147545',
            //         address :'Address1',
            //         pincode :'202020',
            //         status:'Inactive'
            //     },
            //     {
            //         sno:'3',
            //         store_type:'Master Franchise',
            //         store_id:'A00003',
            //         state:'Uttar Pradesh',
            //         gst_no:'GSTIN124532',
            //         contact_no:'9852147545',
            //         address :'Address1',
            //         pincode :'303030',
            //         status:'Active'
            //     },
            //     {
            //         sno:'4',
            //         store_type:'Retail Franchise',
            //         store_id:'A00004',
            //         state:'New Delhi',
            //         gst_no:'GSTIN124532',
            //         contact_no:'9852147545',
            //         address :'Address4',
            //         pincode :'141414',
            //         status:'Active'
            //     },
            //     {
            //         sno:'5',
            //         store_type:'Master Franchise',
            //         store_id:'A00005',
            //         state:'New Delhi',
            //         gst_no:'GSTIN9574685',
            //         contact_no:'9852147545',
            //         address :'Address1',
            //         pincode :'121212',
            //         status:'Inactive'
            //     },
             
            // ]
        }
    },
    methods:{
        status_class(status){
            return status.toLowerCase()=='active'?'badge badge-lg bg-gradient-success':status.toLowerCase()=='inactive'?'badge badge-lg bg-gradient-danger':''
        }
    }
}
</script>

<style>

</style>