<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4 col-lg-6 mb-lg-0">
          <div class="text-sm text-center copyright text-muted"
            :class="this.$store.state.isRTL ? 'text-lg-end' : 'text-lg-start'">
            © {{ new Date().getFullYear() }}, made with
            <i class="fa fa-heart" aria-hidden="true"></i> by
            <a href="https://adsgrill.com/" class="font-weight-bold" target="_blank">Adsgrill</a>
            
            <!-- <a href="https://.com" class="font-weight-bold" target="_blank"></a> -->
            for a better web.
          </div>
        </div>
        <div class="col-lg-5">
          <ul class="nav nav-footer justify-content-center justify-content-lg-end">
            <li class="nav-item">
              <a href="https://adsgrill.com/" class="nav-link text-muted" target="_blank">Adsgrill</a>
            </li>
            <li class="nav-item">
              <a href="https://adsgrill.com/about-us/" class="nav-link text-muted" target="_blank">About Us</a>
            </li>
            <li class="nav-item">
              <a href="https://adsgrill.com/blog/" class="nav-link text-muted" target="_blank">Blog</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "app-footer",
};
</script>
