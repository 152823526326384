<template>
    <div class="row m-2 p-2">
      <head>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />
      </head>
      <!-- Modal FOR ADD HSN -->
      <!-- <div v-if="toggleModal">
        <H1>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium expedita rerum dignissimos eos molestiae autem est consequuntur dolor, reiciendis ipsam aliquam eaque possimus, delectus aspernatur, eligendi qui molestias dolorum sequi!
        </H1>
    
    </div> -->
      
  
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-info shadow-success border-radius-lg pt-4 pb-3 row"
              >
                <h6 class="col-8 text-white text-capitalize ps-3">Quotation</h6>
                <div class="col-4 d-flex justify-content-end align-item-center">
                  <span class="badge badge-sm bg-white text-info d-flex  align-items-center cursor-pointer "
                      
                  >
                        
                          
                        
                        <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-2  ">add</i>
                        Add Quotation
                        
                      
                      </span
                  >
                  <!-- <a-modal v-modal:open="open" title="Basic Modal" @ok="handleok">
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                  </a-modal> -->
                </div>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th
                        class="text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        S.No.
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9 ps-2"
                      >
                        Quotation Id
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Sender Name 
                      </th>
                      
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Receiver Name
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Purchase Order
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Purchase Invoice Id
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Purchase Invoice Number
                      </th>
                      
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Note
                      </th>
                      <th class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="quotation in all_quotations" :key="quotation.sno">
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{ quotation.sno }}</span
                        >
                      </td>
                      
                      <td class="align-middle text-center">
                        <h6 class="mb-0 text-sm inline"> {{ quotation.quotation_id }}</h6>
                        
                        
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold border border-secondary py-1 px-2"
                          >{{ quotation.sender_name }}</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{ quotation.receiver_name }}</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          > {{ quotation.purchase_order }}</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          > {{ quotation.purchase_invoice_id }}</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          > {{ quotation.purchase_invoice_no }}</span
                        >    
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold  cursor-pointer" style="max-width: 100px;"
                            data-bs-toggle="tooltip" data-bs-placement="right" :title="quotation.note"
                            
                          >{{ quotation.note.length>10?quotation.note.substring(0,10)+'...':quotation.note }}</span
                        >
                      </td> 
                      
                      <td class="align-middle text-center">
                        <a
                          href="javascript:;"
                          class="text-secondary font-weight-bold text-xs"
                          data-toggle="tooltip"
                          data-original-title="Edit user"
                        >
                          <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-1">edit</i>
                          <i class="material-icons-round opacity-10 fs-6  text-white bg-danger p-1 rounded-circle mx-1">delete</i>
                      
                        </a>
                      </td>
                    </tr>
                    
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
  </template>
  

<script>
export default {
    name: 'Quotation',
    data() {
        return {
            // all_quotations:[
            //     {
            //         sno:'1',
            //         quotation_id:'Q00001',
            //         sender_name:'sender1',
            //         receiver_name:'receiver1',
            //         purchase_order:'file_po1',
            //         purchase_invoice_id:'P00001',
            //         purchase_invoice_no :'INVA0001',
            //         note:'lorem10 flsjdfl skdf slkdf lsdf lksjd flsk lkwej flksj oiwf lsk'
            //     },
            //     {
            //         sno:'1',
            //         quotation_id:'Q00001',
            //         sender_name:'sender1',
            //         receiver_name:'receiver1',
            //         purchase_order:'file_po1',
            //         purchase_invoice_id:'P00001',
            //         purchase_invoice_no :'INVA0001',
            //         note:'lorem10 flsjdfl skdf slkdf lsdf lksjd flsk lkwej flksj oiwf lsk'
            //     },
            //     {
            //         sno:'1',
            //         quotation_id:'Q00001',
            //         sender_name:'sender1',
            //         receiver_name:'receiver1',
            //         purchase_order:'file_po1',
            //         purchase_invoice_id:'P00001',
            //         purchase_invoice_no :'INVA0001',
            //         note:'lorem10 flsjdfl skdf slkdf lsdf lksjd flsk lkwej flksj oiwf lsk'
            //     },
            //     {
            //         sno:'1',
            //         quotation_id:'Q00001',
            //         sender_name:'sender1',
            //         receiver_name:'receiver1',
            //         purchase_order:'file_po1',
            //         purchase_invoice_id:'P00001',
            //         purchase_invoice_no :'INVA0001',
            //         note:'lorem10 flsjdfl skdf slkdf lsdf lksjd flsk lkwej flksj oiwf lsk'
            //     },
            //     {
            //         sno:'1',
            //         quotation_id:'Q00001',
            //         sender_name:'sender1',
            //         receiver_name:'receiver1',
            //         purchase_order:'file_po1',
            //         purchase_invoice_id:'P00001',
            //         purchase_invoice_no :'INVA0001',
            //         note:'lorem10 flsjdfl skdf slkdf lsdf lksjd flsk lkwej flksj oiwf lsk'
            //     },
            //     {
            //         sno:'1',
            //         quotation_id:'Q00001',
            //         sender_name:'sender1',
            //         receiver_name:'receiver1',
            //         purchase_order:'file_po1',
            //         purchase_invoice_id:'P00001',
            //         purchase_invoice_no :'INVA0001',
            //         note:'lorem10 flsjdfl skdf slkdf lsdf lksjd flsk lkwej flksj oiwf lsk'
            //     },
               
            // ]
        }
    },
    methods:{
        
    }
}
</script>

<style>

</style>