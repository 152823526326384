<template>
    <div class="row m-2 p-2">
      <head>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />
      </head>
      <!-- Modal FOR ADD HSN -->
      <!-- <div v-if="toggleModal">
        <H1>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium expedita rerum dignissimos eos molestiae autem est consequuntur dolor, reiciendis ipsam aliquam eaque possimus, delectus aspernatur, eligendi qui molestias dolorum sequi!
        </H1>
    
    </div> -->
      
  
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-info shadow-success border-radius-lg pt-4 pb-3 row"
              >
                <h6 class="col-8 text-white text-capitalize ps-3">HSN</h6>
                <div class="col-4 d-flex justify-content-end align-item-center">
                  <span class="badge badge-sm bg-white text-info d-flex  align-items-center cursor-pointer "
                      
                  >
                        <button type="primary" @click="showModal"
                        >
                          
                        
                        <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-2  ">add</i>
    
                            Add HSN
                        
                        </button>
                      
                      </span
                  >
                  <!-- <a-modal v-modal:open="open" title="Basic Modal" @ok="handleok">
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                  </a-modal> -->
                </div>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th
                        class="text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        S.No.
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9 ps-2"
                      >
                        HSN ID
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        HSN CODE
                      </th>
                      
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        SGST
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        CGST
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        IGST
                      </th>
                      <th class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="hsn in all_hsn" :key="hsn.sno">
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{ hsn.sno }}</span
                        >
                      </td>
                      
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{ hsn.hsn_id }}</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{ hsn.hsn_code }}</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{ hsn.sgst }}</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          > {{ hsn.cgst }}</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{ hsn.igst }}</span
                        >
                      </td> 
                      
                      <td class="align-middle text-center">
                        <a
                          href="javascript:;"
                          class="text-secondary font-weight-bold text-xs"
                          data-toggle="tooltip"
                          data-original-title="Edit user"
                        >
                          <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-1">edit</i>
                          <i class="material-icons-round opacity-10 fs-6  text-white bg-danger p-1 rounded-circle mx-1">delete</i>
                      
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >1</span
                        >
                      </td>
                      
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >A00001</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >A00001</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >9%</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >9%</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >18%</span
                        >
                      </td> 
                      
                      <td class="align-middle text-center">
                        <a
                          href="javascript:;"
                          class="text-secondary font-weight-bold text-xs"
                          data-toggle="tooltip"
                          data-original-title="Edit user"
                        >
                          <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-1">edit</i>
                          <i class="material-icons-round opacity-10 fs-6  text-white bg-danger p-1 rounded-circle mx-1">delete</i>
                      
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >1</span
                        >
                      </td>
                      
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >A00001</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >A00001</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >9%</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >9%</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >18%</span
                        >
                      </td> 
                      
                      <td class="align-middle text-center">
                        <a
                          href="javascript:;"
                          class="text-secondary font-weight-bold text-xs"
                          data-toggle="tooltip"
                          data-original-title="Edit user"
                        >
                          <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-1">edit</i>
                          <i class="material-icons-round opacity-10 fs-6  text-white bg-danger p-1 rounded-circle mx-1">delete</i>
                      
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >1</span
                        >
                      </td>
                      
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >A00001</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >A00001</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >9%</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >9%</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >18%</span
                        >
                      </td> 
                      
                      <td class="align-middle text-center">
                        <a
                          href="javascript:;"
                          class="text-secondary font-weight-bold text-xs"
                          data-toggle="tooltip"
                          data-original-title="Edit user"
                        >
                          <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-1">edit</i>
                          <i class="material-icons-round opacity-10 fs-6  text-white bg-danger p-1 rounded-circle mx-1">delete</i>
                      
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >1</span
                        >
                      </td>
                      
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >A00001</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >A00001</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >9%</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >9%</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >18%</span
                        >
                      </td> 
                      
                      <td class="align-middle text-center">
                        <a
                          href="javascript:;"
                          class="text-secondary font-weight-bold text-xs"
                          data-toggle="tooltip"
                          data-original-title="Edit user"
                        >
                          <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-1">edit</i>
                          <i class="material-icons-round opacity-10 fs-6  text-white bg-danger p-1 rounded-circle mx-1">delete</i>
                      
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >1</span
                        >
                      </td>
                      
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >A00001</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >A00001</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >9%</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >9%</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >18%</span
                        >
                      </td> 
                      
                      <td class="align-middle text-center">
                        <a
                          href="javascript:;"
                          class="text-secondary font-weight-bold text-xs"
                          data-toggle="tooltip"
                          data-original-title="Edit user"
                        >
                          <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-1">edit</i>
                          <i class="material-icons-round opacity-10 fs-6  text-white bg-danger p-1 rounded-circle mx-1">delete</i>
                      
                        </a>
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
  </template>
  
<script>
// import {ref} from 'vue';
// const open = ref(false);


export default {
    data() {
    return {
        toggleModal:false,
        all_hsn:[
          {
            sno:'1',
            hsn_id:'H0001',
            hsn_code:'78549',
            sgst:'6',
            cgst:'6',
            igst:'12'
          },
          {
            sno:'2',
            hsn_id:'H0003',
            hsn_code:'12345',
            sgst:'9',
            cgst:'9',
            igst:'18'
          },
          {
            sno:'3',
            hsn_id:'H0003',
            hsn_code:'54321',
            sgst:'2.5',
            cgst:'2.5',
            igst:'5'
          },
          {
            sno:'4',
            hsn_id:'H0004',
            hsn_code:'94682',
            sgst:'14',
            cgst:'14',
            igst:'28'
          },
        ]
    }
},
methods: {
showModal()
{   
    console.log('callled')
    this.toggleModal = !this.toggleModal
}
},


}
</script>

<style>

</style>